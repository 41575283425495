import * as THREE from "three";
import Experience from "./Experience.js";

export default class Camera {
  constructor(_options) {
    // Options
    this.experience = new Experience();
    this.config = this.experience.config;
    this.debug = this.experience.debug;
    this.time = this.experience.time;
    this.sizes = this.experience.sizes;
    this.targetElement = this.experience.targetElement;
    this.scene = this.experience.scene;

    this.setInstance();
  }

  setInstance() {
    this.instance = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
  }

  setModes() {}

  resize() {}

  update() {}

  destroy() {}
}
