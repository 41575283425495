import * as THREE from "three";
import Experience from "./Experience.js";
import vertex from "./shader/base/vertex.glsl";
import fragment from "./shader/base/fragment.glsl";
import { MathUtils } from "three";
import gsap from "gsap";
import Audio from "./Utils/Audio.js";
import Splitting from "splitting";
import { Pane } from "tweakpane";

const DEFAULT_UNIFORMS = {
  AMBIENT_NOISE_INTENSITY: 0,
  BLOOM_AMOUNT: 0,
  CRYSTAL_SCALE: 0.4,
  OUTER_BOX_SCALE: 0,
  INNER_BOX_SCALE: 0,
  COLOR_R: 0.49,
  COLOR_G: 0.9,
  COLOR_B: 0,
};

export default class World {
  constructor(_options) {
    this.experience = new Experience();
    this.config = this.experience.config;
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.camera = this.experience.camera.instance;
    this.debug = this.experience.debug;

    Splitting();

    this.interface = document.querySelector(".ui-container");
    this.body = document.querySelector("body");
    const musics = document.querySelectorAll(".musics-wrapper li");

    this.music = null;
    this.current = "flowers";
    this.duration = 0.3;
    this.isPlayed = false;
    this.isLoaded = false;
    this.firstClick = true;
    this.color = "blue";

    this.mouse = {
      x: 0,
      y: 0,
      targetX: 0,
      targetY: 0,
    };

    this.setGeometry();
    this.setMaterial();
    this.setMesh();

    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      document.addEventListener("mousemove", this.setMouseMove, false);
    } else {
      document.addEventListener("touchmove", this.setTouchMove, false);
      document.addEventListener("touchend", this.setTouchEnd, false);
    }

    musics.forEach((music) => {
      music.addEventListener("click", (e) => {
        this.firstClick = false;
        this.playMusic(music.dataset.name);

        if (music.dataset.color == "red") {
          gsap.to(".hue", {
            filter: "hue-rotate(90deg)",
            duration: this.duration * 1.5,
            ease: "power2.out",
          });
          gsap.to(this.material.uniforms.uColorR, {
            value: 0,
            duration: this.duration,
            ease: "power2.out",
            onComplete: () => {
              this.color = "red";

              gsap.to(this.material.uniforms.uColorG, {
                value: 0.87,
                duration: this.duration * 1.5,
                ease: "power2.out",
              });
              gsap.to(this.material.uniforms.uColorB, {
                value: 1,
                duration: this.duration * 1.5,
                ease: "power2.out",
              });
            },
          });
        }
        if (music.dataset.color == "green") {
          gsap.to(".hue", {
            filter: "hue-rotate(-90deg)",
            duration: this.duration * 1.5,
            ease: "power2.out",
          });

          gsap.to(this.material.uniforms.uColorG, {
            value: 1.0,
            duration: this.duration,
            ease: "power2.out",
            onComplete: () => {
              this.color = "green";

              gsap.to(this.material.uniforms.uColorB, {
                value: 0.82,
                duration: this.duration * 1.5,
                ease: "power2.out",
              });

              gsap.to(this.material.uniforms.uColorR, {
                value: 0.78,
                duration: this.duration * 1.5,
                ease: "power2.out",
              });
            },
          });
        }
        if (music.dataset.color == "blue") {
          gsap.to(".hue", {
            filter: "hue-rotate(0deg)",
            duration: this.duration * 1.5,
            ease: "power2.out",
          });

          gsap.to(this.material.uniforms.uColorB, {
            value: 0,
            duration: this.duration,
            ease: "power2.out",
            onComplete: () => {
              this.color = "blue";

              gsap.to(this.material.uniforms.uColorR, {
                value: 0.49,
                duration: this.duration * 1.5,
                ease: "power2.out",
              });
              gsap.to(this.material.uniforms.uColorG, {
                value: 0.9,
                duration: this.duration * 1.5,
                ease: "power2.out",
              });
            },
          });
        }

        musics.forEach((m) => {
          m.classList.remove("isActive");
        });

        music.classList.add("isActive");
      });
    });

    document.querySelector("canvas").addEventListener(
      "click",
      () => {
        if (this.firstClick) {
          this.firstClick = false;
          this.playMusic(this.current);
        } else {
          if (this.music) {
            if (this.isPlayed) {
              this.pauseTimeline();
              gsap.to(this.music.audio, {
                volume: 0,
                duration: this.duration,
                onComplete: () => {
                  this.music.audio.pause();
                },
              });
              this.isPlayed = false;
            } else {
              this.music.audio.play();

              gsap.to(this.music.audio, {
                volume: 1,
                duration: this.duration,
              });

              this.isPlayed = true;
            }
          }
        }
      },
      false
    );

    document.querySelector(".logo-container").addEventListener("click", () => {
      window.location.reload();
    });

    setTimeout(() => {
      this.loadingAnimation();
    }, 500);
  }

  setGeometry() {
    this.geometry = new THREE.PlaneGeometry(2, 2, 8, 8);
  }

  setMaterial() {
    this.imageAspect = 1 / 1;
    this.a1;
    this.a2;

    if (this.config.width / this.config.height > this.imageAspect) {
      this.a1 = (this.config.width / this.config.height) * this.imageAspect;
      this.a2 = 1;
    } else {
      this.a1 = 1;
      this.a2 = (this.config.height / this.config.width) * this.imageAspect;
    }

    this.res = new THREE.Vector4(
      this.config.width,
      this.config.height,
      this.a1,
      this.a2
    );

    this.material = new THREE.ShaderMaterial({
      vertexShader: vertex,
      fragmentShader: fragment,
      side: THREE.FrontSide,
      uniforms: {
        uTime: { value: null },
        uProgress: { value: 2 },
        uNoiseProgress: { value: 1.0 },
        uSpeed: { value: 0.35 },
        uLoadCrystalScale: { value: 0.001 },
        uLoadOuterShapeScale: { value: 0.001 },
        uLoadInnerShapeScale: { value: 0.001 },
        uLoadRotation: { value: Math.PI * 1.35 },
        uLoadBloom: { value: 1.1 },
        uAmbientNoiseIntensity: {
          value: DEFAULT_UNIFORMS.AMBIENT_NOISE_INTENSITY,
        },

        uBloomAmount: { value: DEFAULT_UNIFORMS.BLOOM_AMOUNT },
        uCrystalScale: { value: DEFAULT_UNIFORMS.CRYSTAL_SCALE },
        uOuterBoxScale: { value: DEFAULT_UNIFORMS.OUTER_BOX_SCALE },
        uInnerBoxScale: { value: DEFAULT_UNIFORMS.INNER_BOX_SCALE },
        uColorR: { value: DEFAULT_UNIFORMS.COLOR_R },
        uColorG: { value: DEFAULT_UNIFORMS.COLOR_G },
        uColorB: { value: DEFAULT_UNIFORMS.COLOR_B },
        uMouse: { value: this.mouse },
        uRes: {
          value: this.res,
        },
      },
    });
  }

  setMesh() {
    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.scale.y = 0.002;
    this.mesh.scale.x = 0;

    this.scene.add(this.mesh);
  }

  setMouseMove = (e) => {
    this.mouse.targetX = ((e.clientX / window.innerWidth) * 2 - 1) * 0.8;
    this.mouse.targetY = (-(e.clientY / window.innerHeight) * 2 + 1) * 0.55;

    this.interface.classList.remove("isHide");
    this.body.style.cursor = "inherit";

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.interface.classList.add("isHide");
      this.body.style.cursor = "none";
      gsap.to(this.mouse, {
        targetX: 0,
        duration: 1.15,
        ease: "power2.out",
      });
      gsap.to(this.mouse, {
        targetY: 0,
        duration: 1.15,
        ease: "power2.out",
      });
    }, 5000);
  };

  setTouchMove = (e) => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      this.mouse.targetX = Math.min(
        Math.max((e.targetTouches[0].pageX / window.innerWidth) * 2 - 1, -0.3),
        0.3
      );
      this.mouse.targetY = Math.min(
        Math.max(
          (-(e.targetTouches[0].pageY / window.innerHeight) * 2 + 1) * 2,
          -1.3
        ),
        1.3
      );
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      this.mouse.targetX = Math.min(
        Math.max((e.targetTouches[0].pageX / window.innerWidth) * 2 - 1, -0.65),
        0.65
      );
      this.mouse.targetY = Math.min(
        Math.max(
          -(e.targetTouches[0].pageY / window.innerHeight) * 2 + 1,
          -0.5
        ),
        0.5
      );
    }

    this.interface.classList.add("isHide");
  };

  setTouchEnd = () => {
    this.interface.classList.remove("isHide");
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.interface.classList.add("isHide");
      gsap.to(this.mouse, { targetX: 0, duration: 1.15, ease: "power2.out" });
      gsap.to(this.mouse, { targetY: 0, duration: 1.15, ease: "power2.out" });
    }, 5000);
  };

  playMusic = (src) => {
    if (this.isPlayed) {
      this.pauseMusic(true);
      this.current = src;
    } else {
      this.current = src;
      this.isPlayed = true;
      this.music = new Audio();

      this.music.start({
        live: false,
        analyze: true,
        src: `./assets/${src}.mp3`,
      });
    }
  };

  pauseMusic(play) {
    this.body.style.pointerEvents = "none";
    this.isPlayed = false;
    this.pauseTimeline();
    gsap.to(this.music.audio, {
      volume: 0,
      duration: this.duration,
      onComplete: () => {
        this.music.audio.pause();
        this.body.style.pointerEvents = "all";
        if (play) this.playMusic(this.current);
      },
    });
  }

  pauseTimeline() {
    this.pauseTL = gsap.timeline();
    this.pauseTL.to(
      this.material.uniforms.uCrystalScale,
      {
        value: DEFAULT_UNIFORMS.CRYSTAL_SCALE,
        duration: this.duration,
        ease: "power3.out",
      },
      0
    );

    this.pauseTL.to(
      this.material.uniforms.uInnerBoxScale,
      {
        value: DEFAULT_UNIFORMS.INNER_BOX_SCALE,
        duration: this.duration,
        ease: "power3.out",
      },
      0
    );

    this.pauseTL.to(
      this.material.uniforms.uOuterBoxScale,
      {
        value: DEFAULT_UNIFORMS.OUTER_BOX_SCALE,
        duration: this.duration,
        ease: "power3.out",
      },
      0
    );

    this.pauseTL.to(
      this.material.uniforms.uBloomAmount,
      {
        value: DEFAULT_UNIFORMS.BLOOM_AMOUNT,
        duration: this.duration,
        ease: "power3.out",
      },
      0
    );
    this.pauseTL.to(
      this.material.uniforms.uAmbientNoiseIntensity,
      {
        value: DEFAULT_UNIFORMS.AMBIENT_NOISE_INTENSITY,
        duration: this.duration,
        ease: "power3.out",
      },
      0
    );
    if (this.color == "blue") {
      this.pauseTL.to(
        this.material.uniforms.uColorB,
        {
          value: 0,
          duration: this.duration,
          ease: "power3.out",
        },
        0
      );
    }
    if (this.color == "red") {
      this.pauseTL.to(
        this.material.uniforms.uColorR,
        {
          value: 0,
          duration: this.duration,
          ease: "power3.out",
        },
        0
      );
    }
    if (this.color == "green") {
      this.pauseTL.to(
        this.material.uniforms.uColorG,
        {
          value: 1.0,
          duration: this.duration,
          ease: "power3.out",
        },
        0
      );
    }
  }

  loadingAnimation() {
    this.loadTL = gsap.timeline();
    this.loadTL.to(this.mesh.scale, {
      x: 0.5,
      duration: 1,
    });
    this.loadTL.to(this.mesh.scale, {
      y: 0.5,
      duration: 1,

      onComplete: () => {
        this.body.style.pointerEvents = "all";
      },
    });
    this.loadTL.to(
      this.mesh.scale,
      {
        x: 1,
        duration: 1,
        ease: "power2.inout",
      },
      1
    );
    this.loadTL.to(
      this.mesh.scale,
      {
        y: 1,
        duration: 1,
        ease: "power2.inout",
      },
      1
    );
    this.loadTL.to(
      this.material.uniforms.uNoiseProgress,
      {
        value: 0,
        duration: 1,
        ease: "power2.inout",
      },
      1
    );
    this.loadTL.to(
      this.material.uniforms.uLoadBloom,
      {
        value: 1,
        duration: 1,
        ease: "power2.inout",
      },
      1
    );
    this.loadTL.to(
      this.material.uniforms.uLoadRotation,
      {
        value: 0,
        duration: 1.75,
        ease: "power2.inout",
        onComplete: () => {
          this.isLoaded = true;
        },
      },
      1.1
    );
    this.loadTL.to(
      this.material.uniforms.uLoadCrystalScale,
      {
        value: 1,
        duration: 1.75,
        ease: "power2.inout",
      },
      1.1
    );
    this.loadTL.to(
      this.material.uniforms.uLoadOuterShapeScale,
      {
        value: 1,
        duration: 1.75,
        ease: "power2.inout",
      },
      1.15
    );
    this.loadTL.to(
      this.material.uniforms.uLoadInnerShapeScale,
      {
        value: 1,
        duration: 1.75,
        ease: "power2.inout",
      },
      1.2
    );
    this.loadTL.to(
      ".logo .wrapper span",
      {
        transform: "translateX(0%)",
        duration: 1.6,
        stagger: 0.04,
        ease: "power4.out",
      },
      1.4
    );
    this.loadTL.to(
      ".baseline",
      {
        transform: "translateY(0%)",
        duration: 1.1,
        ease: "power3.out",
      },
      1.45
    );
    this.loadTL.to(
      ".indication-wrapper p",
      {
        transform: "translateY(0%)",
        duration: 1.1,
        stagger: 0.065,
        ease: "power3.out",
      },
      1.5
    );
    this.loadTL.to(
      ".musics-wrapper .wrapper span",
      {
        transform: "translateX(0%)",
        duration: 1.6,
        stagger: 0.04,
        ease: "power4.out",
      },
      1.6
    );
    this.loadTL.to(
      ".li-wrapper li",
      {
        transform: "translateY(0%)",
        duration: 1.1,
        stagger: 0.065,
        ease: "power3.out",
      },
      1.7
    );
    this.loadTL.to(
      ".credits-wrapper p",
      {
        transform: "translateY(0%)",
        duration: 1.1,
        stagger: 0.065,
        ease: "power3.out",
      },
      1.7
    );
  }

  resize() {
    if (this.config.width / this.config.height > this.imageAspect) {
      this.a1 = (this.config.width / this.config.height) * this.imageAspect;
      this.a2 = 1;
    } else {
      this.a1 = 1;
      this.a2 = (this.config.height / this.config.width) * this.imageAspect;
    }

    this.res.set(this.config.width, this.config.height, this.a1, this.a2);
  }

  update() {
    this.material.uniforms.uTime.value = this.time.elapsed * 0.0006;
    if (this.material.uniforms.uTime.value > 250) {
      this.time.elapsed = 0;
    }

    if (this.isLoaded) {
      this.material.uniforms.uMouse.value.x = MathUtils.lerp(
        this.material.uniforms.uMouse.value.x,
        this.mouse.targetX,
        0.05
      );
      this.material.uniforms.uMouse.value.y = MathUtils.lerp(
        this.material.uniforms.uMouse.value.y,
        this.mouse.targetY,
        0.05
      );
    }

    if (!this.music) return;
    this.music.update();

    if (this.isPlayed) {
      this.material.uniforms.uCrystalScale.value = Math.min(
        Math.max(this.music.values[0], 0.4),
        10
      );
      this.material.uniforms.uInnerBoxScale.value = this.music.values[1] * 0.15;
      this.material.uniforms.uOuterBoxScale.value = this.music.values[2] * 0.15;

      this.material.uniforms.uAmbientNoiseIntensity.value =
        this.music.volume * 0.5;
      this.material.uniforms.uBloomAmount.value = this.music.volume * 0.0325;
      if (this.color == "red") {
        this.material.uniforms.uColorR.value = Math.min(
          Math.max(this.music.values[0], 0),
          1
        );
      }
      if (this.color == "green") {
        this.material.uniforms.uColorG.value =
          1.0 - Math.min(Math.max(this.music.values[0], 0), 1) * 0.25;
      }
      if (this.color == "blue") {
        this.material.uniforms.uColorB.value = Math.min(
          Math.max(this.music.values[0], 0),
          1
        );
      }
    }
  }

  destroy() {}
}
