import * as THREE from "three";
import Experience from "./Experience.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";

export default class Renderer {
  constructor(_options = {}) {
    this.experience = new Experience();
    this.config = this.experience.config;
    this.debug = this.experience.debug;
    this.stats = this.experience.stats;
    this.time = this.experience.time;
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.camera = this.experience.camera;

    this.setInstance();
  }

  setInstance() {
    // Renderer
    this.instance = new THREE.WebGLRenderer({
      alpha: false,
      antialias: false,
      powerPreference: "high-performance",
    });
    this.instance.domElement.style.position = "absolute";
    this.instance.domElement.style.top = "50%";
    this.instance.domElement.style.left = "50%";
    this.instance.domElement.style.transform = "translate(-50%, -50%)";
    this.instance.domElement.style.width = "100%";
    this.instance.domElement.style.height = "100%";

    this.clearColor = 0x0c0c0c;

    this.instance.setClearColor(this.clearColor, 1);
    this.instance.setSize(this.config.width, this.config.height);

    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.instance.setPixelRatio(0.75);
    } else {
      this.instance.setPixelRatio(Math.min(window.devicePixelRatio, 1));
    }

    this.context = this.instance.getContext();

    // Add stats panel
    if (this.stats) {
      this.stats.setRenderPanel(this.context);
    }
  }

  resize() {
    // Instance
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.instance.setPixelRatio(0.75);
    } else {
      this.instance.setPixelRatio(Math.min(window.devicePixelRatio, 1));
    }
    this.instance.setSize(this.config.width, this.config.height);
  }

  update() {
    this.instance.render(this.scene, this.camera.instance);

    if (this.stats) {
      this.stats.afterRender();
    }
  }

  destroy() {
    this.instance.dispose();
  }
}
